import { IconConstruction, IconUserManagement, IconCompass } from '../components/Icons';


// services
const s1 = {
  id: 1,
  icon: <IconConstruction />,
  title: "Infrastructure Review and Enhancement",
  description: "We offer a comprehensive review of your current infrastructure, followed by modifications and improvements as needed. This process includes several iterations and gathers feedback to ensure optimal outcomes in the shortest possible timeframe.",
};

const s2 = {
  id: 2,
  icon: <IconCompass />,
  title: "DevOps Implementation from Scratch",
  description: "If you are planning to introduce DevOps practices into your business, we can build your infrastructure from the ground up. We provide tailored solutions designed to integrate seamlessly with your business processes.",
};


const s3 = {
  id: 3,
  icon: <IconUserManagement />,
  title: "Dedicated DevOps Contract Services",
  description: "We can supply one or more DevOps engineers under contract to assist with ongoing issues that arise during your operations. This service is ideal for rapidly growing businesses that require flexible, on-demand support to maintain smooth and efficient processes.",
};

export const services = [s1, s2, s3];

// benefits
const benefit_1 = {
  id: 1,
  icon: 'settings_account_box',
  title: "Streamlined Hiring",
  description: `You will be freed from the burdens of recruitment processes, interviews, and the risks associated with hiring underqualified personnel. All you need to do is discuss your plan with us and sign a contract that clearly outlines the goals and steps involved. 
  This ensures that you always work with top-tier professionals.`,
};

const benefit_2 = {
  id: 2,
  icon: 'verified_user',
  title: "Hassle-Free Operational Management",
  description: "You won't need to worry about taxes, paperwork, termination issues, or other HR tasks. Our service handles all these aspects for you, allowing you to focus more on core business activities without the administrative overhead.",
};

const benefit_3 = {
  icon: 'star',
  id: 3,
  title: "Access to Top-Tier Talent",
  description: "We have carefully selected only the most skilled professionals to join our team. They solve tasks swiftly and efficiently, sharing expertise amongst themselves to continuously improve. In our team, there are no junior or mid-level specialists—only seasoned experts.",
};

export const benefits = [benefit_1, benefit_2, benefit_3];

export const ctas = [
  {
    id: 'touch',
    icon: 'review',
    link: "/offer/review",
    textBefore: "Wondering if your infrastructure is up to par?",
    text: "Get a Free Infrastructure Review",
    btn: "Request",
    textAfter: "No Strings Attached!",
  },
  {
    id: 'hire',
    icon: 'person_add',
    btn: "Get a Free Call",
    link: "/offer/call",
    textBefore: "Want to Try Our Services?",
    text: "Get a Free Consultation",
    textAfter: "No Recruitment Hassles!",
  },
  {
    id: 'contact',
    icon: 'contact_page',
    btn: "Contact Us",
    link: "/contact",
    textBefore: "Need Hassle-Free IT Management?",
    text: "Get in Touch with Us!",
    textAfter: "We're Here to Help",
  },
];
