import React from 'react';
import { Link } from 'react-router-dom';
import { Icon } from './../Icons';
import { scrollToNext } from '../../lib/utils';
import './NavNext.scss';


export default function NavNext({ nextId = '', nextPage = '' }) {

  return (
    <div className="nav-next">
      {nextId ? (
        <span className="nextBtn" onClick={() => scrollToNext(nextId)}>
          <span className={`material-symbols-sharp`} title={`To Next Page`}>keyboard_double_arrow_down</span>
        </span>
      ) : (nextPage ? (
        <span className="nextBtn">
          <Link to={`/${nextPage}`}>
            <span className={`material-symbols-sharp`} title={`To Next Page`}>keyboard_double_arrow_down</span>
          </Link>
        </span>
      ) : (
        <span className="nextBtn">
          <Link to="/contact">
            <span className={`material-symbols-sharp`} title={`To Next Page`}>keyboard_double_arrow_down</span>
          </Link>
        </span>
      )
      )
      }
    </div>
  );
}

