import React, {useEffect   } from 'react';
import { useLocation } from 'react-router-dom';
import TopBar from "./components/TopBar";
import Footer from "./components/Footer";
import Wrapper from './components/Wrapper';
import usePageType from './hooks/usePageType';


export default function Layout({ className, style, children }) {

  const pageType = usePageType();

  return (
    <div className="page">
      <ScrollToTop />

      <Wrapper className={`main ${className ?? ''}`} style={style ?? ''}>
        <TopBar />
        {children}
      </Wrapper>
    {pageType !== 'single' && <Footer />}
    </div>
  )
}



const ScrollToTop = () => {
  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  return null;
};
