import React from 'react';
import { IconCheck, IconClose } from '../Icons';
import './Compare.scss';

const Compare = () => {
  const features = [
    'Expertise',
    'Fast Talent Acquisition',
    '24/7 Support',
    'Cost Efficiency',
    'Scalability',
    'DevOps is Core Business',
  ];

  const house = [true, false, false, false, false, false];
  const aas = [true, true, true, true, true, true];

  return (
    <div className="compare">
      <h2 className='level2'>How We Compare
        <span className='continuation'>
          Against In-house house?
        </span>
      </h2>

      <div className="comparison-table">
        <div className="comparison-header">
          <div className="feature-column">Feature</div>
          <div className="column">In-house DevOps</div>
          <div className="column">DevOps as a Service</div>
        </div>
        {features.map((feature, index) => ( 
          <div className="comparison-row" key={index}>
            <div className="feature-column"><span>{feature}</span></div>
            {house[index] === true ?
              <div className="column true">
                <IconCheck className="icon-check" />
              </div> :
              <div className="column false">
                <IconClose className="icon-times" />
              </div>
            }
            { aas[index] ?
              <div className="column true">
                <IconCheck className="icon-check" />
              </div> :
              <div className="column false">
                <IconClose className="icon-times" />
              </div>
            }
          </div>
        ))}
      </div>
    </div>
  );
};

export default Compare;