
import React from 'react';
import './Secunda.scss';
import NavNext from './NavNext';

const Secunda = ({ nextId = '', nextPage = '' }) => {

  return (

    <section id={`secunda`} className={`page secunda`} >

      <div className={`bg animated`}></div>

      <div className="cell">

        <h1 className="title">
          <span className="one bit-1">We Are</span>
          <span className="two">
            <span className="bit-2">Inno</span>
            <span className="bit-3">Cores</span>
          </span>
        </h1>

        <h2 className="tagline">
          A Prague-based geek team with a knack for designing robust IT infrastructures
        </h2>

        <p className="norm">
          With over a decade of experience in automation, we believe businesses should not be burdened by operational complexities.
          Our expertise spans more than 150 projects, enabling us to deliver solutions that not only meet but exceed the standard workflows.
          We are committed to helping business innovators achieve outstanding results, making their operations efficient and their brands renowned.
        </p>


      </div>
      <NavNext nextId={nextId} nextPage={nextPage} />

    </section>


  );
}

export default Secunda;