import React from "react";
import { Link } from "react-router-dom";
import MenuMobile from './MenuMobile';
import MenuDesktop from "./MenuDesktop";
import { IconBurger } from './Icons';
import {Logo} from './front/Logo';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import './TopBar.scss';
import './front/Logo.scss';


export default function TopBar() {
  return (
    <>
      <ToastContainer />

      <div className={`topbar`}>
        <div className="topbar--inner">
          <div className="group logo-group">
            <Logo size="small" inverse={' inverse'} hasLink={true} />
            <span className="site-tagline">
              DevOps as a Service
            </span>
            <MenuDesktop />
          </div>
          <div className="group group__burger">
            <IconBurger  />
            <span className="contact-btn">
              <Link to="/offer/review">Get Started Today</Link>
            </span>
          </div>
        </div>
      </div>
      
      <MenuMobile />
    </>
  )
}
