
import React from 'react';
import { services, benefits } from '../../lib/data';
import Compare from './Compare';
import Items from './Items';
import NavNext from './NavNext';
import five from '../../assets/images/five.jpg';
import four from '../../assets/images/four.jpg';
import './Tertia.scss';

const Tertia = ({ nextId='', nextPage='' }) => (

  <section id={`tertia`} className={`page tertia`}>
    <img src={five} className="mask-256 c" alt="" />
    <h1 className="title">Our Services</h1>
    <Items items={services} className={'services'} />
    <Compare />
    <h2 className="level1">Enhanced Benefits</h2>
    <Items items={benefits} className="benefits" />
    <NavNext nextId={nextId} nextPage={nextPage} />
    <img src={four} className="mask-256 b two" alt="" />
</section>

);

export default Tertia;