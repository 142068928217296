import React from "react";
import { useParams } from "react-router-dom";
import Layout from '../Layout';
import ReviewOffer from "./front/ReviewOffer";
import CallOffer from "./front/CallOffer";
import CF from "./front/CF";
import './Offer.scss';

export default function Offer() {

  const { offerSlug } = useParams();

  return (
    <Layout>
      <div className={`page offer`}>

        <div className="bg">
        </div>

        <div className="boxed">
          <div className='one'>
            {offerSlug === 'review' ? <ReviewOffer /> : offerSlug === 'call' ? <CallOffer /> : <></>}
            <div className="note">Prefer email? Send us a message at
              <span className='email large'>info@innocores.com</span>
            </div>
          </div>

          <div className='two'>
            <CF />
          </div>

        </div>
      </div>
    </Layout>
  )

}

