import React from 'react';
import { Link } from 'react-router-dom';
import { useCat } from '../contexts/CatContext';

// MDX Icons

export const Icon = ({ slug, title, fn = () => { }, className = '' }) => (
  <span className={`aas-icon ${className}`} onClick={fn}>
    <span className={`material-symbols-sharp`} title={title}>{slug}</span>
  </span>
);

export const IconLogo = () => {

  return (
    <Link to="/" className="aas-icon icon-logo">
      <span className={`material-symbols-sharp`}>airwave</span>
    </Link>
  );
}

export const IconLogo2 = () => {

  return (
    <span className="aas-icon icon-logo">
      <span className={`material-symbols-sharp`}>airwave</span>
    </span>
  );
}


export const IconBurger = () => {

  const { menuOpen, setMenuOpen } = useCat();


  const iconName = menuOpen ? 'close' : 'menu';
  const iconTitle = menuOpen ? 'Close Menu' : 'Open Menu';

  return (
    <span className={`aas-icon burger`} onClick={() => setMenuOpen(!menuOpen)}>
      <span className={`material-symbols-sharp`} title={iconTitle}>{iconName}</span>
    </span>
  );
}

export const IconCheck = ({ className = '' }) => <Icon slug="check" title="Check" className={className} />;
export const IconClose = ({ className = '' }) => <Icon slug="close" title="Close" className={className} />;


// SVG Icons

export const IconFb = ({ link }) => (
  <a href={link} target="_blank" rel="noopener noreferrer" className="icon fb" title="Facebook">
    <svg width="100%" height="100%" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 44 43.78">
      <path d="M22,0C9.85,0,0,9.85,0,22c0,11.03,8.12,20.14,18.71,21.73v-15.9h-5.44v-5.78h5.44v-3.85c0-6.37,3.1-9.17,8.4-9.17,2.54,0,3.88.19,4.51.27v5.05h-3.61c-2.25,0-3.03,2.13-3.03,4.53v3.16h6.59l-.89,5.78h-5.69v15.94c10.74-1.46,19.02-10.64,19.02-21.78C44,9.85,34.15,0,22,0Z" fill="#000" strokeWidth="0" />
    </svg>
  </a>
);


export const IconConstruction = () => (

  <span className="aas-icon item-icon">
    <svg xmlns="http://www.w3.org/2000/svg" height="72px" viewBox="0 -960 960 960" width="72px" fill="currentColor">
    <path d="M772.08-125 522-375.08l53.54-53.54 250.07 250.08L772.08-125Zm-586.23-1-53.54-53.54 292.31-292.31L310-586.46 286.54-563l-42.39-42.77v81.23l-23.07 22.69-117.54-117.54 23.08-23.07h81.84l-45.61-46.23 118-118.39q17.07-16.69 35.57-22.8 18.5-6.12 41.81-6.12 22.92 0 42.61 7.85 19.7 7.84 34.39 24.92l-95.08 94.69 48.69 47.69-24.07 23.7 112.77 112.38 119.31-119.31q-8.16-12.77-12.35-29.38-4.19-16.62-4.19-36 0-51.54 37.3-89.35 37.31-37.8 89.85-37.8 13.69 0 25.04 2.23 11.34 2.23 18.96 7.84l-85.77 85.39 73.46 73.46 86.77-86.39q4.23 7.24 7.46 19.89 3.23 12.65 3.23 26.34 0 52.54-37.8 89.85-37.81 37.31-89.35 37.31-18.77 0-31.85-2.58-13.07-2.58-25.23-9.27L185.85-126Z"/>
    </svg>
  </span>
);

export const IconUserManagement = () => (

  <span className="aas-icon item-icon">
  <svg xmlns="http://www.w3.org/2000/svg" height="72px" viewBox="0 -960 960 960" width="72px" fill="currentColor">
  <path d="M397-502.15q-63.21 0-103.45-40.05t-40.24-103.65q0-63.21 40.24-103.45T397-789.54q63.21 0 103.45 40.24t40.24 103.45q0 63.6-40.24 103.65-40.24 40.05-103.45 40.05ZM82-160.54v-90.47q0-34.99 16.83-61.14 16.83-26.16 48.63-41.23 74.46-34.31 132.89-46.93 58.42-12.61 116.18-12.61h12.43q6.35 0 11.96-1.39-6.46 16.31-9.69 29.54-3.23 13.23-4.85 30.23h-9.81q-52.49 0-104.72 9.73t-118.39 42.96q-18.07 9.54-25.57 22.08t-7.5 28.31v32.54h267.38q3.46 17 11.04 31.73 7.57 14.73 16.5 26.65H82Zm580.14 34.46-9.52-61.54q-16.16-3.23-31.85-12.42-15.69-9.19-26.69-20.35l-52.46 14.16-25.7-42.31 45-40.08q-2.92-8.53-2.92-25.03 0-16.51 2.92-26.58l-43.61-40.46L542-423l52.08 14.54q10.61-10.54 26.41-19.38 15.79-8.85 32.13-14.39l9.52-61.54h52.63l8.31 61.54q14.76 5.23 31.63 14.09 16.87 8.85 27.9 19.29L833.69-423 859-382.08l-44.62 41.46q4.31 11.8 4.31 26.86 0 15.07-4.31 25.14l44 40.08-24.31 42.31-51.46-14.16q-11.38 11.16-28.07 19.85-16.7 8.69-31.46 12.92l-8.31 61.54h-52.63Zm25.52-109q35.88 0 57.42-21.89 21.53-21.89 21.53-56.42 0-34.92-21.74-57.46t-57.61-22.54q-35.49 0-57.22 22.75-21.73 22.74-21.73 57.65 0 34.54 21.93 56.23 21.93 21.68 57.42 21.68ZM397-560.54q36.46 0 60.88-24.23 24.43-24.23 24.43-61.08 0-36.46-24.43-60.88-24.42-24.42-60.88-24.42t-60.88 24.42q-24.43 24.42-24.43 60.88 0 36.85 24.43 61.08 24.42 24.23 60.88 24.23Zm0-85.31Zm10.77 426.93Z"/>
  </svg>
  </span>
);

export const IconCompass = () => (

  <span className="aas-icon item-icon">
    <svg xmlns="http://www.w3.org/2000/svg" height="72px" viewBox="0 -960 960 960" width="72px" fill="currentColor">
    <path d="M270.08-130.54 262-194.23 384.15-532q11.7 9.92 24.73 17.69 13.04 7.77 27.2 11.23L317.15-175.62l-47.07 45.08Zm419.84 0-47.07-45.08-120.16-326.46q13.77-3.84 27.31-11.42t25.23-17.88L698-194.23l-8.08 63.69ZM480.06-530q-48.83 0-82.94-34.13Q363-598.27 363-647.21q0-41.02 24.39-72.83 24.38-31.81 62.92-40.11V-849h58.38v88.85q38.54 8.3 63.42 40.11Q597-688.23 597-647.21q0 48.94-34.06 83.08Q528.89-530 480.06-530Zm-.06-58.38q24.47 0 41.54-17.08 17.08-17.07 17.08-41.54t-17.08-41.54q-17.07-17.08-41.54-17.08t-41.54 17.08q-17.08 17.07-17.08 41.54t17.08 41.54q17.07 17.08 41.54 17.08Z"/>
    </svg>
  </span>
);
