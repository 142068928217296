import React from "react";
import { Routes, Route } from "react-router-dom";
import NotFound from "./components/NotFound";
import Front from "./components/Front";
import Services from "./components/Services";
import Offer from "./components/Offer";
import About from "./components/About";
import Contact from "./components/Contact";
import './App.scss';


export default function App() {


  return (
    <Routes>
      <Route path="*" element={<NotFound />} />
      <Route path="/" element={<Front />} />
      <Route path="/services" element={<Services />} />
      <Route path="/offer/:offerSlug" element={<Offer />} />
      <Route path="/about" element={<About />} />
      <Route path="/contact" element={<Contact />} />
    </Routes>
  );
}
