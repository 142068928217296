export const scrollToTop = () => {
  window.scrollTo({ top: 0, behavior: 'smooth' });
}


export const scrollToNext = (id) => {
  const section = document.getElementById(id);
  if (section) {
    const sectionTop = section.getBoundingClientRect().top + window.pageYOffset;
    const sectionHeight = section.getBoundingClientRect().height;
    const viewportHeight = window.innerHeight;

    let scrollPosition;

    if (sectionHeight > viewportHeight) {
      // Scroll to a position where the top of the section is visible with some offset
      scrollPosition = sectionTop - 20; // Adjust the offset as needed
    } else {
      // Scroll to the top of the section
      scrollPosition = sectionTop;
    }

    window.scrollTo({ top: scrollPosition, behavior: 'smooth' });
  }
};