import React from 'react';
import { Link } from 'react-router-dom';
import { ctas } from '../../lib/data';
import './Cta.scss';

const Cta = ({ idx, color }) => {

  const cta = ctas[idx];
  if (!cta)
    return null;

  return (
    <div className={`cta ${color ? color : ''}`}>
      <div className="cta-text-before">
        {cta.textBefore}
      </div>
      <h2>
        {cta.text}
      </h2>
      <div>
        <button className="button cta-btn"><Link to={cta.link}>{cta.btn}</Link></button>
      </div>
    </div>

  );
}

export default Cta;
