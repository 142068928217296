import React from "react";
import Layout from '../Layout';
import Quarta from "./front/Quarta";

export default function Contact() {

  return (
    <Layout className={"standalone"}>
     <Quarta />
    </Layout>
  )
}

