import React from 'react';
import CF from "./CF";
import './Quarta.scss';

const Quarta = () => {

  return (
    <div id="quarta" className={`page quarta`}>

      <div className="bg">
      </div>

      <div className="boxed">
        <div className='one'>
          <h2>Get in Touch</h2>
          <div className="intro">
            We're passionate about helping you achieve your development goals.
            Whether you're looking to streamline your DevOps processes, refactor your infrastructure, or simply have a question about our services, we're here to help!
          </div>

          <div className="note">Prefer email? Send us a message at        
            <span className='email large'>info@innocores.com</span>
          </div>
        </div>

        <div className='two'>

          <CF />


        </div>

      </div>

    </div>
  );
}

export default Quarta;