import React from 'react';
import { Link } from 'react-router-dom';
import './Footer.scss';

export default function Footer() {

  return (
    <div className={`Footer`}>
      <div className="row">
        {/*}
        <div className="menu-footer">
          <Link to="/page/legal">Legal</Link>
          <Link to="/page/privacy">Privacy Policy</Link>
          <Link to="/page/tos">Terms o Service</Link>
          <Link to="/page/contact">Contacts</Link>
        </div>
  */}

        <span className="copyright">
          © {new Date().getFullYear()}, InnoCores. All Rights Reserved.
        </span>

      </div>
    </div>
  )
}

