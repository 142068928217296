import React from "react";
import Layout from '../Layout';
import Secunda from "./front/Secunda";

export default function About() {

  return (
    <Layout className={"standalone"}>
      <Secunda nextId="" nextPage={'services'} />
    </Layout>
  )
}

