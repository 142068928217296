import React from 'react';
import { Link } from 'react-router-dom';
import { useCat } from '../contexts/CatContext';
import './MenuMobile.scss';

export default function MenuMobile() {

  const { menuOpen } = useCat();

  return (
    menuOpen ?
    <div className="menu--mobile">

      <MenuItem to="/" text="Home" />
      <MenuItem to="/about" text="About" />
      <MenuItem to="/services" text="Services" />
      <MenuItem to="/contact" text="Contact" />

    </div>
    : <></>
  );
  
}

function MenuItem({ to, text }) {
  const { setMenuOpen } = useCat();
  return (
    <span tabIndex={1} className="menu-item" onClick={() => setMenuOpen(false)}>
      <Link to={to}>{text}</Link>
    </span>
  );
} 