export const laNames = {
  ru: 'Русский',
  en: 'English',
}

export const ts = {
  en: [
    { bz: 'Send message'},
    { ca: 'Name'},
    { cb: 'Email'},
    { cc: 'Phone'},
    { cd: 'Your message'},
    { ce: 'Send'},
    { cf: 'Message sent successfully'},
    { cg: 'An error occurred while sending the message. Try again or come back later.'},
    { ch: 'Select Language'}, 
    { cj: 'Legal information' },
    { ck: 'Privacy Policy' },
    { cl: 'Terms of Use' },
    { cm: 'Contacts'},
  ],
ru: [
    { bz: 'Отправить сообщение'},
    { ca: 'Имя'},
    { cb: 'Э.почта'},
    { cc: 'Тел.'},
    { cd: 'Ваше сообщение'},
    { ce: 'Отправить'},
    { cf: 'Сообщение успешно отправлено'},
    { cg: 'При отправке сообщения возникла ошибка. Попробуйте еще раз или вернитесь позже.'},
    { ch: 'Выбрать язык'},
    { cj: 'Правовая информация' },
    { ck: 'Политика конфиденциальности' },
    { cl: 'Условия использования' },
    { cm: 'Контакты'},
    { cn: 'Все права защищены' },
  ],
};

