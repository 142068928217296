import React from "react";
import Layout from '../Layout';
import Tertia from "./front/Tertia";
import './front/Tertia.scss';

export default function Services() {

  return (
    <Layout className={"standalone"}>
      <Tertia nextId="" nextPage="contact"  />
    </Layout>
  )
}

