import React, { useState, useEffect } from "react";
import './AniFlip.scss';

const AniFlip = () => {

  const [index, setIndex] = useState(0);
  const interval = 3000;
  const words = [
    'as a Service',
    'for Startups',
    'to Grow Faster',
    'to Scale Better',
  ];
  const maxIndex = words.length - 1;

  const colors = ['sky', 'blue', 'indigo', 'violet'];

  useEffect(() => {
    const intId = setInterval(() => 
      setIndex(prev => prev < maxIndex ? prev + 1 : 0), interval);
    return () => clearInterval(intId);
  }, []);

  return (
    <div className="animation-flip-word">
      <h1 className="title">
        <span className={`one`}>
          DevOps
        </span>
        <span className={`two ${colors[index]}`}>
          {words[index]}
        </span>
      </h1>
    </div>
  );
}

export default AniFlip;
