import React from 'react';
import './Items.scss';

const Items = ({ items, className = '' }) => (
  <div className={`items ${className}`}>

    {items.length > 0 && items.map((item, index) => (

      <div key={index} className={`item-${items.length}`} id={item.id}>
        {item.icon && typeof item.icon === 'string' ? (
        <span className={`aas-icon ${className}`}>
          <span className={`material-symbols-sharp`} title={item.title}>{item.icon}</span>
        </span>
        ) : (
          item.icon
        )}
        <h3>{item.title}</h3>
        <p>{item.description}</p>
      </div>
    ))}
  </div>
);
export default Items;