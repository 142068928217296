import React from "react";
import { scrollToTop } from "../../lib/utils";
import { Link } from "react-router-dom";
import './Logo.scss';


export const Logo = ({ size, hasLink = false, inverse = '' }) => {


  return (
    <div className={`logo ${size ?? ''} ${inverse ?? ''}`}>
      {hasLink ?
        <span className="one" onClick={scrollToTop}>
          <Link to="/">
            <span className="one">Inno</span>
            <span className="two">Cores</span>
          </Link>
        </span>
        :
        <>
          <span className="one">Inno</span>
          <span className="two">Cores</span>
        </>}
    </div>
  );
}