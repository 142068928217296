import React from "react";
import Layout from '../Layout';
import Prima from "./front/Prima";
import Secunda from "./front/Secunda";
import Tertia from "./front/Tertia";
import Quarta from "./front/Quarta";
import Cta from "./front/Cta";
import './Front.scss';

export default function Front() {

  return (
    <Layout>
      <div className={`Front`}>
        <Prima nextId="secunda" />
        <Cta idx={0} color="bg-000" />
        <Secunda id="secunda" nextId="tertia" />
        <Cta idx={1 } color="bg-000" />
        <Tertia id="tertia" nextId="quarta" />
        <Cta idx={2} color="bg-700" />
        <Quarta nextId="" />
      </div>
    </Layout>
  )
}

