import React from 'react';
import { Link } from 'react-router-dom';
import './MenuDesktop.scss';

export default function MenuDesktop () {

  return (
    <div className="menu--desktop">
      <Link to="/about">About</Link>
      <Link to="/services">Services</Link>
      <Link to="/contact">Contact</Link>
    </div>
);
}
 