import { useParams, useLocation } from 'react-router-dom';

function usePageType() {
  const params = useParams();
  const location = useLocation(); 

  // Check the number of parameters in the URL to determine the page type
  if (location.pathname === '/') {
    return 'home';
  } else if (params.id) { // Assuming 'id' is always a number and is uswed for paintings only
    return 'single';
  } else if ( params.taxSlug && params.termId) { // termId is always a number, taxSlug is always alphanumeric
    return 'term';
  } else if ( params.pageSlug ) {
    return `static-page--${params.pageSlug}`;
  } else {
    // Handle other paths or return a default page type
    return 'unknown';
  }
}

export default usePageType;
