import React, { useState } from 'react';
import DOMPurify from 'dompurify';
import './CF.scss';

export default function CF ( ) {

  const toEmail = 'cto@garant.cloud';

  const [formData, setFormData] = useState({
    name: '',
    email: '',
    phone: '',
    message: ''
  });

  const sentFromSite = 'innocores.com';

  const [statusMessage, setStatusMessage] = useState('');

  const handleChange = (event) => {
    setFormData({
      ...formData,
      [event.target.name]: event.target.value
    });
  };

   
  const sendEmail = async (event) => {
    event.preventDefault(); // Prevent default form submission
    setStatusMessage('Sending...');

    if (!formData.name || !formData.email || !formData.phone || !formData.message) {
      setStatusMessage('Please fill out all fields'); // 
      return;
    }
    if (!formData.email.includes('@')) {
      setStatusMessage('Please enter a valid email address.');
      return;
    }
    if (formData.phone.length < 9) {
      setStatusMessage('Please enter a valid phone number.');
      return;
    }
    if (formData.message.length < 10) {
      setStatusMessage('Message is too short.');
      return;
    }
    if (formData.message.length > 1000) {
      setStatusMessage('Message is too long.');
      return;
    }
    if (formData.name.length > 100) {
      setStatusMessage('Name is too long.');
      return;
    }
    if (formData.email.length > 100) {
      setStatusMessage('Email is too long.');
      return;
    }
    if (formData.phone.length > 100) {
      setStatusMessage('Phone is too long.');
      return;
    }
    
    const url = ''; // 'https://api.brevo.com/v3/smtp/email';
    const emailData = {
      sender: {
        name: '',
        email: ''
      },
      to: [
        {
          name: '',
          email: '',
        }
      ],
      subject: 'New message from website',
      htmlContent: DOMPurify.sanitize(`<html><head></head><body><p>Hello,</p>This is a transactional email sent via Brevo from ${sentFromSite}</p>
      <p>Name: ${formData.name} 
      <br /><br />
      Email:${formData.email} 
      <br /><br />
      Phone: ${formData.phone}
      <br /><br />
      Message:  ${formData.message}
      </p>
      </body></html>`),
    };

    try {
      const response = await fetch(url, {
        method: 'POST',
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json',
          'api-key': '',
        },
        body: JSON.stringify(emailData)
      });

      if (response.ok) {
        setStatusMessage('Message sent! We will get back to you soon.');
      } else {
        setStatusMessage('Failed to send message. Please try again later.');
      }
    } catch (error) {
      console.error('Error sending email:', error);
      setStatusMessage('Failed to send message. Please try again later.');
    }
  };

  
  return (
    <form className={`cf`} onSubmit={sendEmail}>

      <div className='form-row'>
        <label htmlFor="name">
          <span>Name</span>
          <input type="text" id="name" name="name" value={formData.name} onChange={handleChange} required />
        </label>
      </div>

      <div className='form-row'>
        <label htmlFor="email">
        <span>Email</span>
          <input type="text" id="email" name="email" value={formData.email} onChange={handleChange} required />
        </label>
      </div>

      <div className='form-row'>
        <label htmlFor="phone">
        <span>Phone</span>
          <input type="text" id="phone" name="phone" value={formData.phone} onChange={handleChange} required />
        </label>
      </div>

      <div className='form-row textarea'>
        <label htmlFor="message">
        <span>Your Message</span>
          <textarea id="message" name="message" value={formData.message} onChange={handleChange} required />
        </label>
      </div>

      <div className='form-row bttn'>
      <button className="contact-btn" type="submit">Send</button>
      {statusMessage && <span className='msg'>{statusMessage}</span>}
      </div>
    </form>
  );
};



