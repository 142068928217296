import React from 'react';
import CF from "./CF";

export default function CallOffer() {

  return (



    <div className="call-offer">

      <h2><span className="bit-1">Book a Free Phone Call</span></h2>

      <p className='intro'> Have a burning question about DevOps? Want to learn more about our services?</p>

      <p className="note">To get a free call, describe the issue you're experiencing alongside your current development setup, tools and processes.
      </p>

    </div>

  );
}

