import React from 'react';
import AniFlip from './AniFlip';
import { Logo } from './Logo';
import './Prima.scss';
import '../../assets/scss/effects.scss';
import NavNext from './NavNext';


const Prima = ({ nextId, nextPage }) => {

  return (

    <section key={"prima"} id={"prima"} className={'page prima'}>
      <div className="cell">

        <Logo size={'medium'} inverse={false} hasLink={false} />

        <AniFlip />

        <div className="content">
          <h2 className="tagline">
            <span className="bit-1">DevOps</span> <span className="bit-2">as a Service</span> <span className="bit-3">for B2B Startups</span>
          </h2>

          <div className="lead">
            <p>We help our clients free up precious resources by integrating all their DevOps-related task into a single flow managed by our team of seasoned professionals.
              Our pay-as-you-go model ensures that any challenge will be solved swiftly and efficiently.
            </p>
          </div>
        </div>

      </div>

      <div className={`bg animated`}></div>

      <NavNext nextId={nextId} nextPage={nextPage} />
    </section>
  );
}

export default Prima;