import React, {
  createContext, useContext, useState, useMemo,
} from "react";

const CatContext = createContext({
  menuOpen: false,
  setMenuOpen: () => { },
  animating: '', 
  setAnimating: () => { },
});

export default function CatProvider({ children }) {


  const [menuOpen, setMenuOpen] = useState(false);
  const [animating, setAnimating] = useState('');

  const values = useMemo(() => ({
    menuOpen,
    setMenuOpen,
    animating,
    setAnimating,
  }), [menuOpen, animating]); 

  return (
    <CatContext.Provider value={values}>
      {children}
    </CatContext.Provider>
  );

}

export const useCat = () => useContext(CatContext);

