import React from 'react';

export default function ReviewOffer() {

  return (

    <div className="review-offer">

      <h2><span className="bit-1">Get a Free Review</span> <span className="bit-2"> of Your DevOps Current State</span></h2>

      <p className='intro'>Wondering how to optimize your development process?
        Let us assess your current setup and identify areas for improvement. </p>

      <p className="note">To get a free review, describe your current development setup, tools and processes you use.
        Based on this, we will book for 7 you a free 30-minute call with a DevOps specialist.
        We will walk you through your current state and provide tailored recommendations.</p>

      <h4>What you can expect from the review</h4>

      <ul className="list">
        <li>Expert analysis: Our team will assess your DevOps practices and identify potential areas for improvement.</li>
        <li>Actionable insights: You'll receive a clear report outlining our findings and recommendations to optimize your workflow.</li>
        <li>No-obligation guidance: This review is completely free, with no pressure to engage our services.</li>
      </ul>

      <h4>What to include in your request</h4>
      <ul>
        <li>A brief self-introduction: Let us know who you are and the company you represent.</li>
        <li>Your project or challenge: Describe what you're working on or the specific DevOps needs you have.</li>
        <li>Specific questions: Do you have any burning questions about our services or DevOps in general? Fire away!</li>
      </ul>

    </div>

  );
}

