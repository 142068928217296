import React, {
  createContext, useContext, useMemo, useState
} from "react";
import store from 'store2';
import { ts } from "../lib/translate";


const LangContext = createContext({
  lang: '',
  setLang: () => { },
  handleLang: () => { },
  translate: () => { },
  f: () => { },
});

export default function LangProvider({ children }) {

  const initLang = () => {
    const defaultLang = 'ru';
    const acceptedLangs = ['en', 'ru'];
    if (store.has('lang')) {
      const storedLang = store.get('lang');
      if (acceptedLangs.includes(storedLang))
        return storedLang;
    }
    const browserLang = navigator.language.slice(0, 2);
    if (acceptedLangs.includes(browserLang)) {
      store.set('lang', browserLang);
      return browserLang;
    }
    return defaultLang;
  }

  const [lang, setLang] = useState(initLang());

  const handleLang = (l) => {
    if (l === lang)
      return;
    setLang(l);
  }

  function translate(txt, matrix) {
    if (lang === 'en')
      return txt;
    const foundKey = matrix.ru.find(obj => Object.values(obj).includes(txt));
    if (!foundKey)
      return txt;
    const key = foundKey && Object.keys(foundKey)[0];
    const tarObj = key && matrix[lang].find(obj => obj[key]);
    return tarObj ? tarObj[key] : txt;
  };

  const f = (txt) => translate(txt, ts);



  const values = useMemo(() => ({
    lang, setLang, handleLang, translate, f, 
  }), [lang]);


  return (
    <LangContext.Provider value={values}>
      {children}
    </LangContext.Provider>
  );
}

export const useLang = () => useContext(LangContext);
